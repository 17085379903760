import { Controller } from "@hotwired/stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["input", "selectAllCheckbox"];
  static values = {
    selectOnLoad: { type: Boolean, default: false },
  };

  connect() {
    if (this.selectOnLoadValue && this.hasInputTarget) {
      this.selectAllCheckboxTarget.click();
    }
  }

  toggleId(e) {
    const footer = document.getElementById("footer-fixed");
    let selectedValues = JSON.parse(
      footer.getAttribute("data-footer-actions-ids-value")
    );

    if (e.target.checked) {
      selectedValues.push(e.target.value);
    } else {
      _.pull(selectedValues, e.target.value);
    }
    footer.setAttribute(
      "data-footer-actions-ids-value",
      JSON.stringify(selectedValues)
    );
  }

  toggleAll(e) {
    if (e.target.checked) {
      _.filter(this.inputTargets, (target) => !target.checked).map((target) =>
        target.click()
      );
    } else {
      _.filter(this.inputTargets, (target) => target.checked).map((target) =>
        target.click()
      );
    }
  }
}
