import { Controller } from "@hotwired/stimulus";
import Mustache from "mustache";

export default class extends Controller {
  static targets = ["dirtyForm"];
  static values = {
    content: String,
    formUrl: String,
    formMethod: { type: String, default: "POST" },
    isDeleteMethod: { type: Boolean, default: false },
    replacePage: { type: Boolean, default: false },
    open: Boolean,
    parent: { type: String, default: "" },
  };

  connect() {
    if (this.openValue) {
      // if the elemen has the data-modal-open-value="true", open the current modal
      this.openOnLoad();
    }
  }

  openOnLoad() {
    if (this.parentValue) {
      setTimeout(() => {
        document.getElementById(this.parentValue).querySelector(".modal-dialog").classList.add("in-background");
      }, 100);
    }
    setTimeout(() => {
      this.element.classList.add("show");
    }, 150);
  }

  openConfirm(e) {
    e.preventDefault();
    const template = document.getElementById("modal-confirm-template").innerHTML;
    let form = `action="${this.formUrlValue}"`;
    form = `${form} method="${this.formMethodValue}"`;

    if (this.replacePageValue) {
      form = `${form} data-turbo-frame="_top"`;
    } else {
      form = `${form} data-action="turbo:submit-end->modal#closeOnSuccess"`;
    }
    const rendered = Mustache.render(template, {
      form: form,
      body: this.contentValue,
      isDeleteMethod: this.isDeleteMethodValue,
    });
    const backdrop = document.getElementById("static-backdrop");
    backdrop.innerHTML = rendered;
    setTimeout(() => {
      backdrop.classList.add("show");
    }, 150);
  }

  close(e) {
    // Call when a modal is closed by clicking a link
    let canClose = true;
    if (this.hasDirtyFormTarget) {
      const event = new CustomEvent("close-modal", { cancelable: true });
      canClose = this.dirtyFormTarget.dispatchEvent(event);
    }

    if (canClose) {
      this.hideModal(e);
    }
  }

  closeOnSuccess(e) {
    // Call when a modal form submit is finished
    if (e.detail.success) {
      this.hideModal(e);
    }
  }

  hideModal(e) {
    e.preventDefault();
    setTimeout(() => this.element.classList.remove("show"), 150);
    if (this.parentValue) {
      setTimeout(() => {
        document.getElementById(this.parentValue).querySelector(".modal-dialog").classList.remove("in-background");
      }, 200);
    }
    if (this.hasDirtyFormTarget) {
      setTimeout(() => {
        this.dirtyFormTarget.remove();
      }, 300);
    }
  }
}
