import { Controller } from "@hotwired/stimulus";
import { printEPL } from "../lib/qz-helper";
import $ from "jquery";

// Connects to data-controller="product-labels"
export default class extends Controller {
  static targets = ["typology", "label", "button", "buttons"];
  static values = { printerName: String, withVariants: Boolean, sampleId: Number, url: String };

  connect() {
    if (!this.withVariantsValue) {
      // this is a single product, enable button
      this.buttonTarget.classList.remove("disabled");
    }
  }

  updateButton() {
    let counter = 1;
    const isSample = this.typologyTarget.value == "sample";

    if (isSample) {
      counter = counter * 2;
      this.buttonsTarget.classList.add("hide");
    } else {
      this.buttonsTarget.classList.remove("hide");
    }

    if (this.withVariantsValue && !isSample) {
      // Don't increase labels if product don't has variants.
      // Counter doesn't increase for sample labels because sample label refer to main products and not his variants
      const labels = this.labelTargets.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);
      counter = labels.length;
    }

    if (counter > 0) {
      if (counter == 1) {
        this.buttonTarget.innerHTML = "Stampa etichetta";
      } else {
        this.buttonTarget.innerHTML = `Stampa ${counter} etichette`;
      }
      this.buttonTarget.classList.remove("disabled");
    } else {
      this.buttonTarget.innerHTML = "Stampa etichette";
      this.buttonTarget.classList.add("disabled");
    }
  }

  printLabels(e) {
    e.preventDefault();
    const typology = this.typologyTarget.value;
    let ids = [];

    if (typology == "sample") {
      ids.push(this.sampleIdValue);
    } else {
      ids = this.labelTargets.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.value);

      if (ids.length == 0) {
        return;
      }
    }

    const labels = ids.map((id) => ({ id: id, quantity: 1 }));
    console.log(labels);
    const printer = this.printerNameValue;

    $.ajax({
      url: this.urlValue,
      method: "POST",
      dataType: "json",
      data: { labels: { typology: typology, labels: labels } },
    }).done(function (data) {
      printEPL(printer, data);
    });
  }
}
