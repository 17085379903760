import { Controller } from "@hotwired/stimulus"
import { beep, errorBeep } from "../lib/audio";

// Connects to data-controller="audio"
export default class extends Controller {
  static values = {
    isSuccess: { type: Boolean, default: false },
    isError: { type: Boolean, default: false },
  };

  connect() {
    if (this.isSuccessValue) {
      this.successAudio();
    }
    if (this.isErrorValue) {
      this.errorAudio();
    }
  }

  successAudio() {
    beep.play();
  }

  errorAudio() {
    errorBeep.play();
  }
}
