import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import $ from "jquery";

export default class extends Controller {
  static values = { itemsTotal: Number, discountTotal: Number, recalc: Boolean };
  static targets = [
    "productPrice",
    "lineDiscount",
    "grandPrice",
    "orderDiscount",
    "orderDiscountPercent",
    "conventionSelect",
    "line",
    "quantity",
  ];

  connect() {
    // This value is a boolean. Recalculate items total when a row is build server side
    if (this.hasRecalcValue && this.recalcValue) {
      this.calculateItemsTotal();
    }
  }

  calculateGrandPrice() {
    const price = parseFloat(this.productPriceTarget.value) || 0;
    let discount = parseFloat(this.lineDiscountTarget.value) || 0;
    if (discount > 100) {
      // Don't permit discount greather than 100%
      discount = 0;
      this.lineDiscountTarget.value = discount;
    }
    const result = price * ((100 - discount) / 100);
    this.grandPriceTarget.value = result.toFixed(2);

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.calculateItemsTotal();
    }, 800);
  }

  resetLineDiscount() {
    // Remove the line discunt when the product price is manually changed
    this.lineDiscountTarget.value = "";

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.calculateItemsTotal();
    }, 800);
  }

  onLineChange() {
    if (this.hasLineTarget && this.hasQuantityTarget) {
      if (parseInt(this.quantityTarget.value) < 0) {
        this.lineTarget.classList.add("line-returned");
      } else {
        this.lineTarget.classList.remove("line-returned");
      }
    }
    // Recalculate items total when a line value changes
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.calculateItemsTotal();
    }, 800);
  }

  calculateItemsTotal() {
    let total = 0;
    $(".product-line, .manual-line").each((i, obj) => {
      const quantity = parseInt($(obj).find(".js-pos-line-quantity").val()) || 0;
      const price = parseFloat($(obj).find(".js-pos-line-grand-price").val()) || 0;
      total += quantity * price;
    });

    // don't use $(element).data(attr, val) otherwise stimulus won't trigger the change
    $("#js-pos-sidebar").attr("data-pos--calculation-items-total-value", total);
    this.calculateTotalDiscount();
  }

  calculateTotalDiscount() {
    let discount = parseFloat($("#js-pos-order-discount").val()) || 0;
    const discountPercent = parseFloat($("#js-pos-order-discount-percent").val()) || 0;
    if (discountPercent > 0 && discountPercent <= 100) {
      const items = parseFloat($("#js-pos-sidebar").attr("data-pos--calculation-items-total-value")) || 0;
      discount += items * (discountPercent / 100);
    }
    // don't use $(element).data(attr, val) otherwise stimulus won't trigger the change
    $("#js-pos-sidebar").attr("data-pos--calculation-discount-total-value", discount);
  }

  calculateTotal() {
    const items = parseFloat(this.itemsTotalValue) || 0;
    const discount = parseFloat(this.discountTotalValue) || 0;
    const total = items - discount;
    $("#js-pos-total").html(total.toFixed(2));
  }

  setConvention() {
    // Use lodash so an empty string is returned for null and undefined values
    // Native js toString will return undefined for null value
    const value = _.toString(this.conventionSelectTarget.value);
    if (value) {
      // conventions is a JSON present on POS page
      this.orderDiscountPercentTarget.value = conventions[value];
      this.calculateTotalDiscount();
    }
  }

  toggleReturn(e) {
    e.preventDefault();
    if (this.hasQuantityTarget) {
      const quantity = parseInt(this.quantityTarget.value) * -1;
      this.quantityTarget.value = quantity;
      // Not the best way to change the link text
      if (quantity < 0) {
        e.target.innerHTML = "Annulla reso";
      } else {
        e.target.innerHTML = "Segna come reso";
      }
      this.onLineChange();
    }
  }

  deleteLine(e) {
    e.preventDefault();
    if (this.hasLineTarget) {
      this.lineTarget.remove();
      this.calculateItemsTotal();
    }
  }

  itemsTotalValueChanged() {
    $("#js-pos-items-total").html(this.itemsTotalValue.toFixed(2));
    this.calculateTotal();
  }

  discountTotalValueChanged() {
    $("#js-pos-discount-total").html(this.discountTotalValue.toFixed(2));
    this.calculateTotal();
  }
}
