import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="pos--submit"
export default class extends Controller {
  submit(e) {
    e.preventDefault();
    this.element.disabled = true;
    Turbo.navigator.submitForm(document.getElementById("js-pos-form"));
  }
}
