import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { postToUrl } from "../lib/helpers";

// Connects to data-controller="products-search"
export default class extends Controller {
  static targets = ["form"];
  static values = { url: String, id: String };

  submitForm(e) {
    // submit product search form after 800ms and when value have at least 4 chars
    clearTimeout(this.timer);
    if (e.target.value.length > 3) {
      this.timer = setTimeout(() => {
        Turbo.navigator.submitForm(this.formTarget);
      }, 800);
    } else {
      document.getElementById("produt-search-results").classList.add("hide");
    }
  }

  addProduct() {
    postToUrl(this.urlValue, { id: this.idValue });
  }
}
