import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="link"
export default class extends Controller {
  static values = { path: String, redirect: String };
  static targets = ["link"];

  connect() {
    if (this.hasRedirectValue) {
      Turbo.visit(this.redirectValue);
    }
  }

  goTo() {
    Turbo.visit(this.pathValue);
  }

  disableOnClick(e) {
    // utility to add a "disabled" class to a link or a button
    e.target.classList.add("disabled");
  }

  clickTarget() {
    this.linkTarget.click();
  }
}
