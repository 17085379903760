import { Controller } from "@hotwired/stimulus"
import _ from "lodash";
import $ from "jquery";

// Connects to data-controller="products--short-description-generation"
export default class extends Controller {
  static targets = ["hiddenInput", "enableLink", "disableLink", "valueInput", "source"];

  connect() {
    this.changeLink();
  }

  enable(e) {
    e.preventDefault();
    this.hiddenInputTarget.value = "true";
    this.changeLink();
    this.getValue();
  }

  disable(e) {
    e.preventDefault();
    this.hiddenInputTarget.value = "false";
    this.changeLink();
  }

  changeLink() {
    if (this.hiddenInputTarget.value == "true") {
      this.enableLinkTarget.classList.add("hide");
      this.disableLinkTarget.classList.remove("hide");
      this.valueInputTarget.disabled = true;
    } else {
      this.enableLinkTarget.classList.remove("hide");
      this.disableLinkTarget.classList.add("hide");
      this.valueInputTarget.disabled = false;
    }
  }

  onInputChange() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getValue();
    }, 700);
  }

  getValue() {
    if (this.hiddenInputTarget.value != "true") {
      return;
    }
    let values = {};
    this.sourceTargets.forEach((t) => {
      if (t.value != "") {
        const name = t.name.replace("product[", "").replace("]", "");
        values[name] = t.value;
      }
    });

    if (_.isEmpty(values)) {
      return;
    }

    $.ajax({
      method: "POST",
      url: "/products/generate-short-description",
      data: values,
    }).done((data) => {
      this.valueInputTarget.innerHTML = data.result;
    });
  }
}
